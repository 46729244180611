import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
    background-image: url('/img/wedding_place/background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 297px;
    padding: 85px 15px 0 15px;
    margin-bottom: 40px;
    h2 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
    }
    p {
        font-size: 20px;
        text-align: center;
        max-width: 330px;
        margin: 0 auto;
    }
    @media (min-width: 992px) {
        padding-top: 120px;
        margin-bottom: 100px;
        margin-top: 100px;
        height: 424px;

        h2 {
            /* padding-top: 120p */
            font-size: 48px;
            margin-bottom: 40px;
            font-weight: 500;
            line-height: 100%;
        }
        p {
            font-size: 24px;
            text-align: center;
            max-width: 425px;
            margin: 0 auto;
        }
    }
`

const WeddingPlace = () => {
    return (
        <>
            <Wrapper id="place">
                <h2>Место проведения</h2>
                <p>
                    г. Екатеринбург, ул. Бахчиванджи 55а, Отель «AZIMUT», Банкетный{'\u00A0'}зал{'\u00A0'}«Янтарь»
                </p>
            </Wrapper>
        </>
    )
}

export default WeddingPlace
