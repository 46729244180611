import styled from 'styled-components'
import React from 'react'

const Wrapper = styled.section`
    padding: 0 15px;
    margin-bottom: 40px;
    @media (min-width: 992px) {
        margin-bottom: 100px;
    }
`
const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &:last-child {
        justify-content: center;
    }
    @media (min-width: 992px) {
        column-gap: 80px;
    }
`
const Item = styled.div`
    max-width: 160px;
    align-items: center;
    @media (min-width: 992px) {
        max-width: 320px;

    }
`
const ItemImg = styled.div`
    display: flex;

    img {
        margin: 0 auto;
        width: 80px;
        height: 80px;
    }
    @media (min-width: 992px) {
        margin-bottom: 14px;
        img{
            width: 100px;
            height: 100px;
        }
    }
`
const ItemText = styled.p`
    color: #1b1a1a;
    text-align: center;
    line-height: 1.2;
    @media (min-width: 992px) {
        font-size: 20px;

    }
`

const Wishes = () => {
    return (
        <>
            <Wrapper>
                <Items>
                    <Item>
                        <ItemImg>
                            <img src="/img/wishes/wishes_1.svg" alt="" />
                        </ItemImg>
                        <ItemText>Свои теплые слова и пожелания приносите в сердцах, а подарки - в конверте </ItemText>
                    </Item>
                    <Item>
                        <ItemImg>
                            <img src="/img/wishes/wishes_2.svg" alt="" />
                        </ItemImg>
                        <ItemText>Вместо цветов приносите с собой бутылочку вина для нашей домашней винотеки</ItemText>
                    </Item>
                    <Item>
                        <ItemImg>
                            <img src="/img/wishes/wishes_3.svg" alt="" />
                        </ItemImg>
                        <ItemText>
                            Будем благодарны, если вы воздержитесь от криков «Горько», ведь поцелуй - знак выражения
                            чувств{' '}
                        </ItemText>
                    </Item>
                </Items>
            </Wrapper>
        </>
    )
}

export default Wishes
