import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    padding: 130px 20px 200px 20px;
    background-image: url('/img/header/header_tree.webp');
    background-size: 230px 254px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    margin-bottom: 40px;
    @media (min-width: 992px) {
        background-size: 480px 530px;
        padding: 160px 0 144px 0;
        background-position-y: 0;
        margin-bottom: 0;
    }
`
const NamesBlock = styled.div`
    background-image: url('/img/header/ampersant.svg');
    background-repeat: no-repeat;
    background-position: center;
    max-width: 300px;
    margin: 0 auto;
    @media (min-width: 992px) {
        background-size: 81px 96px;
        max-width: 586px;
    }
`
const MaxinName = styled.p`
    font-family: Playfair Display;
    font-size: 50px;
    font-weight: 400;
    line-height: 100%;
    @media (min-width: 992px) {
        font-size: 96px;
    }
`
const VictoriaName = styled(MaxinName)`
    text-align: right;
`

const Inviting = styled.p`
    text-align: center;
    margin-top: 48px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 25px;
    @media (min-width: 992px) {
        font-size: 36px;
        margin-top: 100px;
        margin-bottom: 35px;
    }
`
const MainMenu = styled.div`
    display: none;
    @media (min-width: 992px) {
        display: flex;
        max-width: 785px;
        column-gap: 40px;
        margin: 0 auto;
        a {
            font-size: 20px;
            color: #1b1a1a;
            &:hover {
                color: #157a79;
            }
        }
    }
`

const WeddingDate = styled.img`
    width: 100%;
    @media (min-width: 992px) {
        display: block;
        max-width: 531px;
        margin: 0 auto;
    }
`

const Header = () => {
    return (
        <>
            <MainMenu>
                <a href="#place">Место проведения</a>
                <a href="#program">Программа</a>
                <a href="#dress">Дресс-код</a>
                <a href="#confirm">Подтверждение</a>
                <a href="#contacts">Контакты</a>
            </MainMenu>
            <Wrapper>
                <NamesBlock>
                    <MaxinName>Максим</MaxinName>
                    <VictoriaName>Виктория</VictoriaName>
                </NamesBlock>
                <Inviting>Приглашают на свадьбу</Inviting>
                <WeddingDate src="/img/header/wedding_date.svg" alt="Дата свадьбы"></WeddingDate>
            </Wrapper>
        </>
    )
}

export default Header
