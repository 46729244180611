import styled from 'styled-components'
import React, { useState } from 'react'
import css from '../../styles/components'
import { useSearchParams } from 'react-router-dom'

const { BlockHeader } = css
const Header = styled(BlockHeader)`
    background-image: url('/img/anketa/anketa.png');
    @media (min-width: 992px) {
        background-image: url('/img/anketa/bg_desktop.png');
    }
`

const Wrapper = styled.section`
    margin-bottom: 40px;
    padding: 0 15px;
    @media (min-width: 992px) {
        padding: 0;
        max-width: 1120px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 100px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 618px;
            height: 418px;
            background-image: url('/img/anketa/tree_anketa.webp');
            background-size: 618px 418px;
            background-repeat: no-repeat;
        }
    }
`
const Question = styled.p`
    color: #1b1a1a;
    font-size: 16px;
    margin-bottom: 15px;
    @media (min-width: 992px) {
        font-size: 24px;
        max-width: 421px;
        margin-bottom: 10px;
    }
`
const RadioBlock = styled.div`
    margin-bottom: 15px;
`
const RadioItem = styled.div`
    span {
        font-size: 16px;
    }
    &:first-child {
        margin-bottom: 11px;
    }
    @media (min-width: 992px) {
        span {
            font-size: 20px;
        }
    }
`
const RadioInput = styled.input`
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #157a79;
    margin-right: 10px;
    transform: translateY(5px);
    @media (min-width: 992px) {
        margin-right: 15px;
    }
    &:checked {
        background-color: #157a79;
    }
`
const Wishes = styled.p`
    margin-bottom: 10px;
    @media (min-width: 992px) {
        font-size: 24px;
    }
`
const TextArea = styled.textarea`
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
    max-width: 421px;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    &:focus-visible {
        outline: none;
    }
`
const Button = styled.button`
    display: block;
    padding: 10px 50px 12px 50px;
    border-radius: 5px;
    background: #157a79;
    border: 1px solid #157a79;
    color: #fff;
    &:hover {
        background-color: #fff;
        color: #1b1a1a;
        border: 1px solid #1b1a1a;
    }
    @media (min-width: 992px) {
        font-size: 20px;
        line-height: 1;
    }
`

const Anketa = (prop) => {
    // eslint-disable-next-line
    const [params, setParams] = useSearchParams()
    const [answer, setAnswer] = useState('Да, с удовольствием')
    const [comment, setComment] = useState('')
    const man = params.get('mr')
    const woman = params.get('ms')

    function guests(man, woman) {
        let and
        if (man && woman) {
            and = ' и '
        } else if (!man && !woman) {
            and = 'Без ссылки';
        } else {
            and = ''
        }
        return `${man ? man : ''}${and}${woman ? woman : ''}`
    }

    function sendToTelegram() {

        let postData = {
            'answer': answer,
            'comment': comment,
            'guests': guests(man, woman)
        }
        fetch('https://develop.rum-opt.ru/wedding', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams(postData).toString(),
          });
          prop.action(1)
          setTimeout(() => {
            prop.action(0)
          }, 2000);
    }

    function changeRadio(event) {
        setAnswer(event.target.value)
    }

    function handleComments(event) {
        setComment(event.target.value)
    }

    return (
        <>
            <Header id="confirm">Анкета гостя</Header>
            <Wrapper>
                <Question>Планируете ли вы присутствовать на свадьбе?</Question>
                <RadioBlock>
                    <RadioItem>
                        <label htmlFor="yes">
                            <RadioInput
                                type="radio"
                                name="answer"
                                id="yes"
                                value="Да, с удовольствием"
                                checked={answer==="Да, с удовольствием"}
                                onChange={changeRadio}
                            />
                            <span>Да, с удовольствием</span>
                        </label>
                    </RadioItem>
                    <RadioItem>
                        <label htmlFor="no">
                            <RadioInput
                                type="radio"
                                name="answer"
                                id="no"
                                value="К сожалению, не могу"
                                checked={answer==="К сожалению, не могу"}
                                onChange={changeRadio}
                            />
                            <span>К сожалению, не могу</span>
                        </label>
                    </RadioItem>
                </RadioBlock>
                <Wishes>Ваши пожелания</Wishes>
                <TextArea onChange={handleComments} type="textarea" rows="5" cols="33" name="comments" />
                <Button onClick={sendToTelegram}>Отправить</Button>
            </Wrapper>
        </>
    )
}

export default Anketa
