import styled from 'styled-components'
import React from 'react'

const GoodBye = () => {
    return (
        <>
            <Wrapper>
                <HappyToseeYou>Будем рады видеть вас</HappyToseeYou>
                <Yours>Ваши:</Yours>
                <Names>
                    <Maxim>Максим</Maxim>
                    <Victoria>Виктория</Victoria>
                </Names>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.section`
    padding-bottom: 82px;
    background-image: url('/img/goodbye/tree.webp');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 224px 136px;
    @media (min-width: 992px) {
        background-size: 516px 313px;

    }
`
const HappyToseeYou = styled.p`
    font-size: 20px;
    color: #1b1a1a;
    text-align: center;
    margin-bottom: 5px;
    @media (min-width: 992px) {
        font-size: 32px;
    }
`
const Yours = styled.p`
    font-size: 20px;
    color: #1b1a1a;
    text-align: center;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 6px;
    @media (min-width: 992px) {
        font-size: 32px;
    }
`
const Names = styled.div`
    background-image: url('/img/header/ampersant.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px 20px;
    max-width: 119px;
    margin: 0 auto;
    @media (min-width: 992px) {
        background-size: 51px 60px;
        max-width: 231px;
        transform: translate(305px, -40px)
    }
`
const Maxim = styled.p`
    font-size: 20px;
    color: #1b1a1a;
    @media (min-width: 992px) {
        font-size: 39px;
    }
`
const Victoria = styled(Maxim)`
    text-align: right;
`



export default GoodBye
