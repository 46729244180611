import React from 'react'
import styled from 'styled-components'
import css from '../../styles/components'

const { BlockHeader } = css
const Header = styled(BlockHeader)`
    background-image: url('/img/dresscode/header.png');
    @media (min-width: 992px) {
        background-image: url('');
    }
`

const Dresscode = () => {
    return (
        <>
            <Header id="dress">Дресс-код</Header>
            <Wrapper>
                <p>
                    Мы будем благодарны, если вы поддержите
                    <br />
                    стилистику нашей свадьбы при выборе наряда
                </p>
                <Colors>
                    <img src="/img/dresscode/color_1.png" alt="Цвет" />
                    <div></div>
                    <img src="/img/dresscode/color_3.png" alt="Цвет" />
                    <img src="/img/dresscode/color_4.png" alt="Цвет" />
                    <img src="/img/dresscode/color_5.png" alt="Цвет" />
                </Colors>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.section`
    margin-bottom: 40px;
    padding: 0 15px;
    p {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.2;
    }
    @media (min-width: 992px) {
        margin-bottom: 60px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: -100px;
            right: 0;
            width: 300px;
            height: 480px;
            background-image: url('/img/dresscode/tree_dress.webp');
            background-size: 300px 480px;
            background-repeat: no-repeat;
        }
        p {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
`

const Colors = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    img {
        width: 50px;
        height: 50px;
    }
    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: 0px 1px 10px 0px rgba(4, 47, 46, 0.15);
        min-width: 50px;
    }
    @media (min-width: 360px) {
        img {
            width: 58px;
            height: 58px;
        }
        div {
            width: 58px;
            height: 58px;
            border-radius: 50%;
            box-shadow: 0px 1px 10px 0px rgba(4, 47, 46, 0.15);
            min-width: 58px;
        }
    }
    @media (min-width: 992px) {
        gap: 30px;
        img {
            width: 90px;
            height: 90px;
        }
        div {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            box-shadow: 0px 1px 10px 0px rgba(4, 47, 46, 0.15);
            min-width: 90px;
        }
    }
`



export default Dresscode
