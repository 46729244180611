import styled from 'styled-components';

const css = {
    BlockHeader: styled.h2`
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
    background-image: url("/img/dearguests/we_marring.webp");
    background-repeat: no-repeat;
    background-position: center;
    @media (min-width: 992px) {
        font-size: 48px;
        margin-bottom: 40px;
        font-weight: 500;
        padding-top: 20px;
        /* height: 100px; */
        vertical-align: bottom
    }
    `
}

export default css
