import React from 'react'
import styled from 'styled-components'
import css from '../../styles/components'
import { useSearchParams } from 'react-router-dom'

const { BlockHeader } = css

const DearGuests = () => {

    // http://localhost:3000/?mr=Роман&ms=Алина
    // http://localhost:3000/?ms=Алина
    // http://localhost:3000/?mr=Роман
    // http://maxim-victoria.ru/?mr=Евгений&ms=Ольга
    // http://maxim-victoria.ru/?ms=Мария
    // http://maxim-victoria.ru/?mr=Дмитрий

    // eslint-disable-next-line
    const [ params, setParams ] = useSearchParams()

    const man = params.get("mr")
    const woman = params.get("ms")

    const dear = (man, woman) => {
        let result;
        if (man && !woman) result = 'Дорогой'
        if (!man && woman) result = 'Дорогая'
        if (man && woman) result = 'Дорогие'
        if (!man && !woman) result = 'Дорогие гости'
        return result
    }
    // let and;
    function guests(man, woman) {
        let and;
        if(man && woman) {
            and = ' и '
        } else {
            and = ""
        }
        return `${man ? man : ""}${and}${woman ? woman : ""}`
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <Header>
                        {dear(man, woman)}
                        <br /> {guests(man, woman)}
                    </Header>
                    <BlockWrapper>
                        <FirstBlock>
                            <p>Мы счастливы пригласить вас на нашу свадьбу.</p>
                            <p>
                                Для нас очень важно, чтобы вы смогли разделить с нами самое счастливое мгновение в нашей
                                жизни. Порадуйте нас своим присутствием.
                            </p>
                        </FirstBlock>
                        <SecondBlock>
                            <img src="/img/dearguests/rings.webp" alt="Кольца" />
                        </SecondBlock>
                        <ThirdBlock>
                            <Name>Максим</Name>
                            <img src="/img/dearguests/maxim.webp" alt="Максим" />
                            <p>
                                Я не верил в любовь с первого взгляда, пока не встретил тебя. В тот момент, я сразу
                                понял, что ты и есть та самая, единственная!
                            </p>
                        </ThirdBlock>
                        <FourthBlock>
                            <Name>Виктория</Name>
                            <img src="/img/dearguests/victoria.webp" alt="Виктория" />
                            <p>
                                Я верю в судьбу и в мечты. Знаю, что с тобой я буду всегда под защитным крылышком, за
                                сильной спиной, и в твоём любящем сердце. Я готова открыть с тобой новую главу нашей
                                совместной жизни!
                            </p>
                        </FourthBlock>
                    </BlockWrapper>
                </Wrapper>
            </Container>
        </>
    )
}

const Header = styled(BlockHeader)`
    @media (min-width: 992px) {
        br {
            display: none;
        }
        background-image: url('/img/dearguests/bg_desktop.png');
    }
`

const Container = styled.div`
    @media (min-width: 992px) {
        background-image: url('/img/dearguests/dear_tree.webp');
        background-size: 258px 408px;
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: 0;
    }
`

const Wrapper = styled.section`
    margin-bottom: 40px;
    font-size: 16px;
    padding: 0 15px;
    font-weight: 400;
    @media (min-width: 992px) {
        padding: 0;
        max-width: 1120px;
        margin: 0 auto;
        /* margin-bottom: 100px; */
    }
`
const BlockWrapper = styled.div`
    @media (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;
        column-gap: 120px;
    }
`

const FirstBlock = styled.div`
    margin-bottom: 14px;
    p:first-child {
        margin-bottom: 10px;
    }
    @media (min-width: 992px) {
        max-width: 500px;
        padding-right: -10px;
        p {
            font-size: 24px;
        }
    }
`
const SecondBlock = styled.div`
    margin-bottom: 30px;
    @media (min-width: 992px) {
        max-width: 500px;
    }
`
const ThirdBlock = styled.div`
    margin-bottom: 30px;
    img {
        margin-bottom: 10px;
    }
    @media (min-width: 992px) {
        max-width: 500px;
        p {
            font-size: 20px;
        }
    }
`
const FourthBlock = styled.div`
    img {
        margin-bottom: 10px;
    }
    @media (min-width: 992px) {
        max-width: 500px;
        p {
            font-size: 20px;
        }
    }
`

const Name = styled.h2`
    text-align: center;
    margin-bottom: 10px;
    font-size: 24px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%;
        height: 0.5px;
        background: #157a7849;
    }
    @media (min-width: 992px) {
        margin-bottom: 30px;
        font-size: 36px;
    }
`


export default DearGuests
