import React from 'react'
import styled from 'styled-components'
import css from '../../styles/components'

const { BlockHeader } = css


const Programma = () => {
    return (
        <>
            <Wrapper id="program">
                <Header>Программа</Header>
                <Items>
                    <Item>
                        <Time>
                            <img src="/img/programma/time_1.webp" alt="Время" />
                        </Time>
                        <TextBlock>
                            <h3>Сбор гостей</h3>
                            <p>Собираемся и знакомимся</p>
                        </TextBlock>
                    </Item>
                    <Item>
                        <Time>
                            <img src="/img/programma/time_2.webp" alt="Время" />
                        </Time>
                        <TextBlock>
                            <h3>Выездная регистрация</h3>
                            <p>Радуемся и поздравляем</p>
                        </TextBlock>
                    </Item>
                    <Item>
                        <Time>
                            <img src="/img/programma/time_3.webp" alt="Время" />
                        </Time>
                        <TextBlock>
                            <h3>Начало банкета</h3>
                            <p>Отдыхаем и веселимся</p>
                        </TextBlock>
                    </Item>
                </Items>
            </Wrapper>
        </>
    )
}



const Wrapper = styled.section`
    padding: 0 15px;
    margin-bottom: 40px;
    @media (min-width: 992px) {
        margin-bottom: 100px;
        padding: 0;
    }
`

const Items = styled.div`
    @media (min-width: 992px) {
        display: flex;
        column-gap: 156px;
        margin: 0 auto;
        justify-content: center;
    }
`

const Item = styled.div`
    display: flex;
    @media (min-width: 992px) {
        flex-direction: column;
        align-items: center;
        &:not(:last-child) {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 48%;
                right: -73%;
                width: 150px;
                height: 1px;
                background: #157a79;
                transform: rotate(105deg);
            }
        }
    }
`

const Time = styled.div`
    width: 80px;
    height: 87px;
    @media (min-width: 992px) {
        width: 90px;
        height: 90px;
    }
`
const TextBlock = styled.div`
    padding-left: 10px;
    padding-top: 15px;
    h3 {
        color: #1b1a1a;
        font-size: 16px;
        font-weight: 600;
    }
    p {
        font-size: 16px;
    }
    @media (min-width: 350px) {
        h3 {
            color: #1b1a1a;
            font-size: 20px;
            font-weight: 600;
        }
    }
    @media (min-width: 992px) {
        padding: 0;
        h3 {
            font-size: 24px;
            text-align: center;
            margin-bottom: 10px;
        }
        p {
            font-size: 20px;
            text-align: center;
        }
    }
`

const Header = styled(BlockHeader)`
    background-image: url('/img/programma/header.webp');
    @media (min-width: 992px) {
        background-image: url('/img/programma/bg_desktop.png');
    }
`



export default Programma
