import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


  * {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }

  &::before,
  &::after {
    box-sizing: border-box;
  }

  nav,
  footer,
  header,
  aside {
    display: block;
  }

  ::focus,
  ::active {
    outline: none;
  }

  html,
  body {
    color: #000;
    height: 100%;
    width: 100%;
    font-size: 100%;
    /* line-height: 1; */
    scroll-behavior: smooth;
    font-size: 16px;
    font-family: 'Playfair Display';
  }

  input,
  button,
  textarea {
    font-family: inherit;
  }

  input::-ms-clear {
    display: none;
  }

  button {
    cursor: pointer;
    background-color: #fff;
  }

  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  ul li {
    list-style: none;
  }

  img {
    vertical-align: top;
    max-width: 100%
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
`;

export default GlobalStyle;
