import React, { useState } from 'react'
import Header from '../blocks/header'
import DearGuests from '../blocks/dearguest'
import WeddingPlace from '../blocks/wedding_place'
import Programma from '../blocks/programm'
import Dresscode from '../blocks/dresscode'
import Wishes from '../blocks/wishes'
import Anketa from '../blocks/anketa'
import Contacts from '../blocks/contacts'
import GoodBye from '../blocks/goodbye'
import styled, { keyframes } from 'styled-components'

const Main = () => {
    const [showModal, setShowModal] = useState(0)
    return (
        <>
            <Container>
                <Header />
                <DearGuests />
                <WeddingPlace />
                <Programma />
                <Dresscode />
                <Wishes />
                <Anketa action={setShowModal} />
                <Contacts />
                <GoodBye />
                <Success showModal={showModal}>
                    <p>Ваш ответ отправлен!</p>
                </Success>
                <SnowConrainer>
                    <div></div>
                    <p></p>
                </SnowConrainer>
            </Container>
        </>
    )
}

const Container = styled.main`
    /* max-width: 1440px; */
    margin: 0 auto;
    padding-top: 40px;
    position: relative;
`
const Success = styled.div`
    position: absolute;
    top: 38vh;
    left: 10%;
    width: 80%;
    height: 100px;
    z-index: 2;
    background-color: #157a79;
    max-width: 300px;
    position: fixed;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    opacity: ${(props) => (props.showModal ? 1 : 0)};
    display: ${(props) => (props.showModal ? 'flex' : 'none')};
    @media (min-width: 992px) {
        left: 40vw;
    }
    p {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
    }

    `

const AnimationSnow = keyframes`
    from {
        background-position: 0px 0px;
    }

    to{
        background-position: 300px 300px;
    }
`

const SnowConrainer = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    top: -126px;
    left: 0;
    z-index: 8;
    @media (min-width: 992px) {
        top: 75px;
    }
    div {
        width: 100%;
        height: 775px;
        background-image: url('/img/snow/snow_1.png');
        animation: ${AnimationSnow} 30s linear infinite;
    }
`


export default Main
