import styled from 'styled-components'
import React from 'react'
import css from '../../styles/components'

const { BlockHeader } = css
const Header = styled(BlockHeader)`
    background-image: url('/img/contacts/header.png');
    @media (min-width: 992px) {
        background-image: url('/img/contacts/bg_desktop.png');
    }
`

const Wrapper = styled.section`
    padding: 0 15px;
    margin-bottom: 40px;
    @media (min-width: 992px) {
        margin-bottom: 70px;
    }
`
const SubTitle = styled.p`
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    line-height: 110%;
    margin-bottom: 20px;
    font-size: 16px;
    @media (min-width: 992px) {
        font-size: 24px;
        max-width: 480px;
        margin-bottom: 30px;
    }
`
const Items = styled.div`
    @media (min-width: 992px) {
        display: flex;
        justify-content: center;
        column-gap: 165px;
    }
`
const Item = styled.div`
    &:first-child {
        margin-bottom: 14px;
    }
`
const ItemHeader = styled.p`
    color: #1b1a1a;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    @media (min-width: 992px) {
        font-size: 24px;
    }
`
const ItemBody = styled.div`
    display: flex;
`
const ItemPicture = styled.div`
    img {
        width: 58px;
        height: 58px;
    }
    @media (min-width: 992px) {
        img {
            width: 80px;
            height: 80px;
        }
    }
`
const ItemTextBlock = styled.div`
    padding: 5px 0 0 10px;
    @media (min-width: 992px) {
        padding: 5px 0 0 14px;
    }
`
const ItemName = styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    @media (min-width: 992px) {
        font-size: 20px;
    }
`
const ItemPhone = styled.a`
    color: #1b1a1a;
    font-size: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    @media (min-width: 992px) {
        font-size: 20px;
    }
`

const Contacts = () => {
    return (
        <>
                <Header id="contacts" >Контакты</Header>
            <Wrapper>
                <SubTitle>По всем вопросам и нюансам в день торжества обращайтесь: </SubTitle>
                <Items>
                    <Item>
                        <ItemHeader>Жених</ItemHeader>
                        <ItemBody>
                            <ItemPicture>
                                <img src="/img/contacts/maxim.png" alt="Максим" />
                            </ItemPicture>
                            <ItemTextBlock>
                                <ItemName>Максим</ItemName>
                                <ItemPhone href="tel:+79655356611">+7 (965) 535-66-11</ItemPhone>
                            </ItemTextBlock>
                        </ItemBody>
                    </Item>
                    <Item>
                        <ItemHeader>Организатор</ItemHeader>
                        <ItemBody>
                            <ItemPicture>
                                <img src="/img/contacts/olesya.png" alt="Олеся" />
                            </ItemPicture>
                            <ItemTextBlock>
                                <ItemName>Олеся</ItemName>
                                <ItemPhone href="tel:+79222120022">+7 (922) 212-00-22</ItemPhone>
                            </ItemTextBlock>
                        </ItemBody>
                    </Item>
                </Items>
            </Wrapper>
        </>
    )
}

export default Contacts
